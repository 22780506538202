import axios from "@/utils/http";
import base from "@/api/base";
import request from "@/utils/request";

const arrangeRecord = {
    // 获取最新一条
    getLast() {
        return request({
            url: '/v2/school_api/arrange/record/last',
        })
    },
    // 开始排课
    start() {
        return request({
            url: '/v2/school_api/arrange/record/start',
            method: 'post',
        })
    }
}
export default arrangeRecord
