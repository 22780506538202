<template>
  <div>
    <v-index>
      <el-breadcrumb>
        <el-breadcrumb-item>教务</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">智能排课</a></el-breadcrumb-item>
         <el-breadcrumb-item><a style="color: #000000;">自动排课</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content">
        <div v-loading="loading" class="loading">
          {{loading ? '排课中....' : '无任务'}}
        </div>

        <div class="content-footer">
          <el-button size="small" @click="$router.back()">返回</el-button>
          <el-button size="small" type="primary" :disabled="loading" @click="onStart">{{loading ? '排课中' : '开始排课'}}</el-button>
        </div>

        <div v-if="loading" class="tip">
          温馨提示：您可以离开当前页面，无需等待执行完成
        </div>
      </div>
    </v-index>
  </div>
</template>

<script>
import VIndex from "@/components/common";
import arrangeRecord from "@/api/v2/record";
export default {
  name: "auto",
  components: {VIndex},
  data() {
    return {
      loading: false,
      timer: 0
    }
  },
  mounted() {
    this.getLast()
    this.timer = setInterval(() => {
      this.getLast()
    }, 3000)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    async getLast() {
      const resp = await arrangeRecord.getLast()
      if (resp.data.status === 1 || resp.data.status === 0) {
        this.loading = true
      } else {
        this.loading = false
      }
    },
    onStart() {
      this.loading = true;
      arrangeRecord.start();
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin-top: 20px;
  background: #fff;
  &-footer {
    text-align: center;
    padding: 20px;
  }
}
.tip {
  text-align: center;
  color: #333;
  padding-bottom: 30px;
}
.loading {
  text-align: center;
  padding: 30px 0;
  font-size: 30px;
  font-weight: bold;
}
</style>
